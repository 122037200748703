<template>
  <!-- ======= Header ======= -->
  <header id="header" class="fixed-top">
    <div class="container d-flex align-items-center justify-content-between">

      <a href="#" class="logo">
        <img :src="logo" alt="Logo" class="img-fluid" width="120" height="90">
      </a>
      <nav id="navbar" class="navbar">
        <ul>
          <li><a class="nav-el" href="#">Strona główna</a></li>
          <li><a class="nav-el" href="#contact-us">Kontakt</a></li>
          <li><a class="nav-el" href="http://www.oberzaborsuki.pl" target="_blank">
            Oberża w Borsukach <i class="bi bi-arrow-up-right"></i></a></li>
        </ul>
        <ul class="navbar-mobile-custom">
          <li><a class="nav-el" href="#contact-us">Kontakt</a></li>
        </ul>
      </nav><!-- .navbar -->

    </div>
  </header><!-- End Header -->

  <!-- ======= Hero Section ======= -->
  <section id="hero">
    <div id="heroCarousel" data-bs-interval="5000" class="carousel slide carousel-fade"
         data-bs-ride="carousel" style="filter: brightness(1.05);">
      <ol class="carousel-indicators" id="hero-carousel-indicators"></ol>
      <div class="carousel-inner" role="listbox" aria-label="Slider">
        <p class="mobile-title"><a href="#main" role="button">
          Kajaki Borsuki<br><i style="font-weight: bold" class="bi bi-arrow-down"></i>
        </a></p>
        <div
            class="active carousel-item" :style="images[0]">
        </div>
        <div v-for="img in images.slice(1)" :key="img"
             :class="{'carousel-item': true }" :data-url="img['backgroundImage']">
        </div>
      </div>
      <a class="carousel-control-prev" href="#heroCarousel" role="button" data-bs-slide="prev"
         aria-label="Poprzedni">
        <span class="carousel-control-prev-icon bi bi-chevron-left"></span>
      </a>

      <a class="carousel-control-next" href="#heroCarousel" role="button" data-bs-slide="next"
         aria-label="Następny">
        <span class="carousel-control-next-icon bi bi-chevron-right"></span>
      </a>
    </div>
  </section>
  <main id="main">
    <section id="about" class="about">
      <div class="container">

        <div class="section-title">
          <h2>Kajaki Borsuki - spływy Bugiem</h2>
          <p>
            Witamy na stronie wypożyczalni kajaków Oberża Borsuki, oferujemy spływy malowniczą rzeką
            Bug na odcinku od Gnojna do Mierzwic, przez Serpelice i Zabuże. Więcej informacji o
            trasie
            <a href="https://www.bugunitesus.eu/pl/szlaki-kajakowe/szlak-bugiem/gnojno-mielnik-144-km-polska/"
               target="_blank" rel="nofollow"><b>Gnojno-Mielnik</b></a>
          </p>
          <br>
          <p>
            Bug przepływa przez Park Krajobrazowy "Podlaski Przełom Bugu" którego krajobraz
            urozmaicają niewysokie, łagodnie zarysowane wzgórza, pozostałości po ostatnim
            zlodowaceniu. Głównym walorem przyrodniczym parku jest właśnie niepoddana regulacji
            rzeka Bug, wolna od ludzkiej ingerencji tworzy unikatowy krajobraz.
          </p>
          <br>
          <p>
            Okolica rzeki wyróżniają się znacznym zróżnicowaniem siedlisk i występujących w nich
            gatunków. Fauna również jest różnorodna – należy do niej blisko 100 gatunków motyli
            dziennych (około 70% wszystkich polskich gatunków), 44 ryb i 158 gatunków ptaków.
          </p>
        </div>
      </div>
    </section>
    <p><b>
      Dlaczego nasza wypożyczalnia?
    </b></p>
    <section id="whyUs">
      <div class="container">
        <div class="row" style="margin: 20px;">
          <div class="col-md-3" style=" padding: 10px;">
            <div class="card">
              <img class="card-img-top" :src="whyImages[3]" width="375" height="250"
                   alt="Card image cap">
              <div class="card-body">
                <p class="card-title" style="font-weight: 600;">Transport <i
                    class="bi bi-car-front-fill"></i></p>
                <p class="card-text">Zapewniamy transport uczestników jak i sprzętu, na miejsce
                  spływu kajakowego na rzece Bug.</p>
              </div>
            </div>
          </div>
          <div class="col-md-3" style=" padding: 10px;">
            <div class="card">
              <img class="card-img-top" :src="whyImages[2]" width="375" height="250"
                   alt="Card image cap">
              <div class="card-body">
                <p class="card-title" style="font-weight: 600;">Niskie ceny <i
                    class="bi bi-piggy-bank-fill"></i></p>
                <p class="card-text">Zapewniamy wypożyczenie nowych kajaków, kamizelek i wioseł do
                  spływu w konkurencyjnej cenie.</p>
              </div>
            </div>
          </div>
          <div class="col-md-3" style=" padding: 10px;">
            <div class="card">
              <img class="card-img-top" :src="whyImages[1]" width="375" height="250"
                   alt="Card image cap">
              <div class="card-body">
                <p class="card-title" style="font-weight: 600;">Lokalizacja <i
                    class="bi bi-geo-alt-fill"></i></p>
                <p class="card-text">Bliskie położenie wypożyczalni względem rzeki Bug pozwala na
                  szybki dojazd na miejsce spływu.</p>
              </div>
            </div>
          </div>
          <div class="col-md-3" style=" padding: 10px;">
            <div class="card">
              <img class="card-img-top" :src="oberza" width="375" height="250" alt="Card image cap">
              <div class="card-body">
                <p class="card-title" style="font-weight: 600;">Oberża <i
                    class="bx bx-restaurant"></i></p>
                <p class="card-text">Oberża Borsuki to miejsce w którym można zjeść i odpocząć,
                  oferujemy domowe posiłki, nocleg i parking.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="contact-us" class="contact-us">
      <div class="container">
        <div class="section-title">
          <h2>Kontakt</h2>
        </div>
        <div class="row mt-5" style="min-height: 300px">
          <div class="col-lg-4">
            <div class="info">
              <div class="address">
                <i class="bi bi-geo-alt"></i>
                <h3>Adres:</h3>
                <p><a href="https://goo.gl/maps/oE6KieFsLB9SSwkJ9" target="_blank" ref="nofollow">
                  Borsuki 45, 08-221 Borsuki
                </a></p>
              </div>
              <div class="email">
                <i class="bi bi-envelope"></i>
                <h3>Email:</h3>
                <p><a href="mailto:oberzaborsuki@gmail.com">oberzaborsuki@gmail.com</a></p>
              </div>
              <div class="phone">
                <i class="bi bi-phone"></i>
                <h3>Telefon:</h3>
                <p><a href="tel:+48512373900">512 373 900</a></p>
                <p><a href="tel:+48512373909">512 373 909</a></p>
              </div>
            </div>
          </div>

          <div class="col-lg-8 mt-5 mt-lg-0" id="mapDiv">
          </div>
        </div>
      </div>
    </section>

  </main>

  <!-- ======= Footer ======= -->
  <footer id="footer">
    <div class="container">
      <h4 style="font-size: 1.5rem">Zapraszamy do Oberży w Borsukach!</h4>
      <p></p>
      <div class="social-links">
        <a href="https://www.facebook.com/pages/Ober%C5%BCa/1384333235224903" target="_blank"
           ref="nofollow" class="facebook" aria-label="FB"><i class="bx bxl-facebook"></i></a>
        <a href="https://goo.gl/maps/oE6KieFsLB9SSwkJ9" target="_blank" ref="nofollow"
           aria-label="Mapa"><i class="bx bx-map"></i></a>
        <a href="mailto:oberzaborsuki@gmail.com" aria-label="Email"><i class="bx bxl-gmail"></i></a>
        <a href="tel:+48512373900" aria-label="Telefon"><i class="bx bx-phone"></i></a>
      </div>
      <div class="credits">
        <h5>Do zobaczenia!</h5>
      </div>
    </div>
  </footer><!-- End Footer -->

</template>

<script>
// import { nextTick } from 'vue'

export default {
  name: 'HomePage',
  mounted: function () {
    this.$nextTick(() => {
      const carouselItems = document.querySelectorAll(".carousel-item");
      carouselItems.forEach((item) => {
        if (!item.classList.contains('active')) {
          item.style.backgroundImage = item.dataset.url
        }
      });
      const addMap = () => {
        if (!document.querySelector('.map')) {
          const iframe = document.createElement("iframe");
          iframe.classList.add('map');
          iframe.title = 'Mapa';
          iframe.src = "https://www.google.com/maps/embed/v1/place?q=Oberża+w+Borsukach,+restauracja+i+noclegi.,+Borsuki,+Polska&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8";
          document.getElementById('mapDiv').appendChild(iframe)
          window.removeEventListener('scroll', addMap);
        }
      }
      window.addEventListener('scroll', addMap);
    })
  },
  setup() {
    const support_format_webp = () => {
      let elem = document.createElement('canvas');
      if (elem.getContext && elem.getContext('2d')) {
        return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
      } else {
        return false;
      }
    }
    const addCanonical = () => {
      const link = document.createElement('link')
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', window.location.href.split("#")[0]);
      document.head.appendChild(link);
    }
    addCanonical()
    let sliderImages, whyImages, logo, oberza
    if (support_format_webp()) {
      sliderImages = require.context('@/assets/photos/Bug', false, /\.webp$/);
      logo = require('@/assets/Logo_Oberza_Borsuki.webp')
      oberza = require('@/assets/ob.webp')
      whyImages = require.context('@/assets/photos/Bug_internet', false, /\.webp$/);
    } else {
      sliderImages = require.context('@/assets/photos/Bug', false, /\.jpg$/);
      logo = require('@/assets/Logo_Oberza_Borsuki.svg')
      oberza = require('@/assets/ob.jpg')
      whyImages = require.context('@/assets/photos/Bug_internet', false, /\.jpg$/);
    }
    sliderImages = sliderImages.keys().map(sliderImages);
    const imagesMap = sliderImages.map(x => {
      return {
        backgroundImage: `url(${x})`
      }
    });
    whyImages = whyImages.keys().map(whyImages);
    return {
      whyImages,
      logo,
      images: imagesMap,
      oberza,
    }
  }
}
</script>
